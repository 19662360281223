<template>
  <div style="width: 100%" class="mb-6">
    <v-row class="ml-1">
      <v-col cols="6" class="pb-0">
        <p class="black--text font-weight-bold text-h6 mr-2 mt-2">Nhập từ đúng </p>
        <p class="text-body-1 red--text font-weight-medium">(Sắp xếp theo thứ tự từ trên xuống dưới - Kéo thả để sắp xếp lại các từ)</p>
        <draggable :list="dataWord" handle=".word-draggable" @change="(e) => eventResetOffsetDataWord(e)">
          <template v-for="(objWord, i) in dataWord">
            <div :key="i" style="width: 80%" class="d-flex">
              <v-icon class="pb-6 word-draggable" style="cursor: grabbing" left>mdi-sort</v-icon>
              <v-text-field
                  outlined dense
                  class="mt-0"
                  :label="'Từ đúng thứ ' + (i + 1)"
                  placeholder="Nhập từ đúng"
                  :value="objWord.content"
                  @input="(e) => eventChangeContentWord(e, i)"
              ></v-text-field>
              <v-icon
                  class="pb-6" dense large @click="deleteWordSentenceScramble(i)">
                mdi-delete
              </v-icon>
            </div>
          </template>
        </draggable>
        <div style="width: 180px" class="d-flex cursor-pointer" @click="addWordSentenceScramble()">
          <v-btn outlined icon color="sliver">
            <v-icon color="sliver">mdi-plus</v-icon>
          </v-btn>
          <span class="text-h6 mt-1 ml-1">Thêm từ đúng</span>
        </div>
      </v-col>

      <v-col cols="6" class="pb-0">
        <p class="black--text font-weight-bold text-h6 mr-2 mt-2">Nhập từ nhiễu </p>
        <template v-for="(obfuscate, i) in dataObfuscate">
          <div :key="i" style="width: 80%" class="d-flex">
            <v-text-field
                outlined dense
                class="mt-0"
                :label="'Từ nhiễu thứ ' + (i + 1)"
                placeholder="Nhập từ nhiễu"
                v-model="obfuscate.content"
            ></v-text-field>
            <v-icon class="pb-5" dense large @click="deleteObfuscate(i)">mdi-delete</v-icon>
          </div>
        </template>
        <div
            v-if="!dataObfuscate.length"
            style="width: 100%"
            class="secondary rounded text-center p-4 label_default mb-2"
        >
          <span class="grey--text">Chưa có từ nhiễu nào, hãy bấm nút tạo từ nhiễu</span>
        </div>
        <div style="width: 180px" class="d-flex cursor-pointer" @click="addObfuscate()">
          <v-btn outlined icon color="sliver">
            <v-icon color="sliver">mdi-plus</v-icon>
          </v-btn>
          <span class="text-h6 mt-1 ml-1">Thêm từ nhiễu</span>
        </div>
      </v-col>

      <v-col cols="12" class="pb-0 pl-0">
<!--        <div class="mt-4 ">-->
<!--          <p class="label_default">Danh sách từ đúng:</p>-->
<!--          <div style="min-width: 65%; border: 1px solid grey" class="rounded p-1 mb-2 ml-1 user-none-select">-->
<!--            <template v-for="(word, i) in dataWord">-->
<!--                <span :key="i" class="item-list-correct lighten-1 text&#45;&#45;black p-3 rounded d-inline-block" v-bind:class="{ 'light-blue': word.content, 'grey': !word.content }">-->
<!--                  {{ word.content ? word.content : '(none)' }}-->
<!--                </span>-->
<!--            </template>-->
<!--          </div>-->
<!--        </div>-->
        <div class="mt-4" style="width: 100%">
          <p class="label_default mb-1">Các đáp án đúng:</p>
          <p v-if="dataCorrect.length" class="text-body-1 red--text font-weight-medium">(Sắp xếp các từ để tạo thành 1 câu cho 1 đáp án đúng)</p>
          <template v-for="(objCorrect, iObjCorrect) in dataCorrect">
            <div :key="iObjCorrect" class="d-flex">
              <p class="text-body-1 font-weight-medium mb-0 mt-4">Đáp án đúng #{{iObjCorrect + 1}}:</p>
              <div style="min-width: 85%; max-width: 85%; border: 1px solid grey" class="rounded p-3 mb-2 ml-1 user-none-select">
                <draggable :list="objCorrect.dataDraggableWord">
                  <template v-for="(objWord, iObjWord) in objCorrect.dataDraggableWord">
                    <span
                        :key="iObjWord"
                        class="item-list-correct lighten-1 text--black p-3 rounded-xl d-inline-block"
                        v-bind:class="{ 'light-blue': objWord.content, 'grey': !objWord.content }"
                        style="cursor: grabbing">
                      {{ objWord.content ? objWord.content : '( No value )' }}
                      <v-icon @click="deleteObjWordInDataCorrect(iObjCorrect, iObjWord)" small class="ml-1 cursor-pointer">mdi-close</v-icon>
                    </span>
                  </template>
                </draggable>

              </div>
              <v-btn outlined color="grey" class=" ml-1" @click="deleteCorrect(iObjCorrect)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>

            </div>
          </template>
          <div
              v-if="!dataCorrect.length"
            style="width: 100%"
            class="secondary rounded text-center p-4 label_default"
          >
            <span class="grey--text">Chưa có đáp án nào, hãy bấm nút tạo đáp án đúng</span>
          </div>

          <v-btn color="#039BE5" outlined class="mt-4" @click="addCorrect()">
            <v-icon left color="#039BE5">mdi-plus</v-icon>
            <span style="color: #039BE5 !important;">Đáp án đúng</span>
          </v-btn>
        </div>
      </v-col>


    </v-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "SentenceScramble",
  components:{
    draggable,
  },
  props: {
    dataWordProp: {
      type: Array,
      default: () => [{uuid: null, content: "", hashed: null, offset: 1, oldOffset: 1,}],
    },
    dataObfuscateProp: {
      type: Array,
      default: () => [{uuid: null, content: "", hashed: null, offset: 1,}],
    },
    dataCorrectProp: {
      type: Array,
      default: () => [
        { dataDraggableWord:
          [
            {uuid: null, content: "", hashed: null, offset: 1,}
          ]
        }
      ],
    },
  },
  data() {
    return {}
  },
  computed: {
    dataWord: {
      get() {
        return this.dataWordProp;
      },
      set(value) {
        this.$emit("setDataWord", value);
      },
    },
    dataObfuscate: {
      get() {
        return this.dataObfuscateProp;
      },
      set(value) {
        this.$emit("setDataObfuscate", value);
      },
    },
    dataCorrect: {
      get() {
        return this.dataCorrectProp;
      },
      set(value) {
        this.$emit("setDataCorrect", value);
      },
    },

  },
  methods: {
    messError(mess) {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    addWordSentenceScramble() {
      // get offset largest in dataWord
      let offset = 0;
      this.dataWord.forEach((word) => {
        if (word.offset > offset) {
          offset = word.offset;
        }
      });
      offset = offset + 1;
      let objWord = {uuid: null, content: "", hashed: null, offset: offset, oldOffset: offset,};
      this.dataWord.push(objWord);

      // add dataWord to dataCorrect by offset
      this.dataCorrect.forEach((objCorrect) => {
        objCorrect.dataDraggableWord.push(objWord);
      });

      this.eventResetOffsetDataWord();
    },
    eventChangeContentWord(contentValue, index) {
      this.dataWord[index].content = contentValue;

      // update dataWord to dataCorrect by offset
      let vm = this;
      this.dataCorrect.forEach((objCorrect) => {
        objCorrect.dataDraggableWord.forEach((objWord) => {
          if (objWord.offset === vm.dataWord[index].offset) {
            objWord.content = contentValue;
          }
        });
      });
    },
    deleteWordSentenceScramble(index) {
      if (this.dataWord.length <= 1) {
        this.messError("Không thể xóa từ đúng cuối cùng");
        return;
      }

      // delete dataWord in dataCorrect by offset
      let vm = this;
      this.dataCorrect.forEach((objCorrect) => {
        objCorrect.dataDraggableWord.forEach((objWord, i) => {
          if (objWord.offset === vm.dataWord[index].offset) {
            objCorrect.dataDraggableWord.splice(i, 1);
          }
        });
      });

      this.dataWord.splice(index, 1);

      this.eventResetOffsetDataWord();
    },
    eventResetOffsetDataWord() {
      let vm = this;
      this.dataWord.forEach((word, index) => {
        let newOffset = index + 1;

        // update offset in dataCorrect
        vm.dataCorrect.forEach((objCorrect) => {
          objCorrect.dataDraggableWord.forEach((objWord) => {
            if (objWord.oldOffset === word.oldOffset) {
              objWord.offset = newOffset;
            }
          });
        });

        // update offset in dataWord
        word.offset = newOffset;
      });

      // reset oldOffset in dataWord and dataCorrect
      this.dataWord.forEach((word) => {
        word.oldOffset = word.offset;
      });
      this.dataCorrect.forEach((objCorrect) => {
        objCorrect.dataDraggableWord.forEach((objWord) => {
          objWord.oldOffset = objWord.offset;
        });
      });
    },
    addObfuscate() {
      // get offset largest in dataObfuscate
      let offset = 0;
      this.dataObfuscate.forEach((obfuscate) => {
        if (obfuscate.offset > offset) {
          offset = obfuscate.offset;
        }
      });
      this.dataObfuscate.push({uuid: null, content: "", hashed: null, offset: offset+1,});
    },
    deleteObfuscate(index) {
      // if (this.dataObfuscate.length <= 1) {
      //   this.messError("Không thể xóa từ nhiễu cuối cùng");
      //   return;
      // }
      this.dataObfuscate.splice(index, 1);
    },
    addCorrect() {
      //validate item_content not null in dataWord
      if (!this.validateCorrect()) {
        return;
      }

      let dataWord = JSON.parse(JSON.stringify(this.dataWord));
      let objCorrect = {dataDraggableWord: dataWord};
      this.dataCorrect.push(objCorrect);
    },
    validateCorrect() {
      let check = true;

      this.dataWord.forEach((word, index) => {
        if (!word.content) {
          this.messError("Từ đúng thứ " + (index + 1) + " không được để trống");
          check = false;
        }
      });
      return check;
    },
    deleteCorrect(index) {
      // if (this.dataCorrect.length <= 1) {
      //   this.messError("Không thể xóa đáp án cuối cùng");
      //   return;
      // }
      this.dataCorrect.splice(index, 1);
    },
    deleteObjWordInDataCorrect(iObjCorrect, iObjWord) {
      if (this.dataCorrect[iObjCorrect].dataDraggableWord.length <= 1) {
        // this.addCorrect();
        this.dataCorrect.splice(iObjCorrect, 1);
        return;
      }

      this.dataCorrect[iObjCorrect].dataDraggableWord.splice(iObjWord, 1);
    },
  },
}
</script>

<style scoped>
.label_default {
  font-size: 16px;
}
.item-list-correct {
  max-width: max-content;
  margin:5px;;
}
.user-none-select {
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
}
</style>